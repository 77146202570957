import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { displayMonth } from '../utils/dateformat';
import JSXSchema from '../components/JSXSchema';

function EventTemplate({
  pageContext: {
    title,
    id,
    image,
    clock,
    date,
    provider,
    venue,
    zipCode,
    onsale,
    city,
    description,
  },
}) {
  const handleDate = (eventDate) => {
    let dates = eventDate.split('-');
    if (provider === 'Musikhuset') {
      dates = eventDate.split('T')[0].split('-');
    }
    const day = dates[2];
    const month = dates[1];
    const year = dates[0];

    return `${day}. ${displayMonth(month)} ${year}`;
  };

  const handleClock = (localTime) => {
    if (!localTime && provider !== 'Musikhuset') return null;
    if (provider === 'Musikhuset') {
      return "kl " + date.split('T')[1].substring(0, 5);
    }
    const [hour, minute] = localTime.split(':');
    return `kl ${hour}.${minute}`;
  };

  const handleImage = (images, ticketProvider) => {
    if (ticketProvider === 'Billetlugen' || ticketProvider === 'Musikhuset') {
      return images;
    }

    const eventImg = images.filter((e) => e.width === 640);
    return eventImg[0].url;
  };

  const handleStatus = () => {
    if (title.toLowerCase().match('udsolgt')) return 'Udsolgt';
    if (title.toLowerCase().match('aflyst')) return 'Aflyst';
    if (provider === 'Ticketmaster' && onsale !== 'onsale')
      return 'Ikke aktuel';
    if (provider === 'Billetlugen' && onsale !== true) return 'Ikke aktuel';
    return 'Køb billet';
  };

  const handleSEO = () => {
    let seoDescription = `Læs mere om ${title} og se hvor du kan købe billetter`;
    if (provider === 'Billetlugen' && description) {
      const fixDescription = description
        ?.replace(/(<([^>]+)>)/g, ' ')
        ?.slice(0, 155)
        ?.split('.');

      if (fixDescription.length && fixDescription.length >= 2) {
        seoDescription = fixDescription.slice(0, -1).join('.');
      }
    }

    return seoDescription;
  };

  return (
    <>
      <SEO title={title} description={handleSEO()} />
      <Layout withoutHeader>
        <JSXSchema
          type="Event"
          image={handleImage(image, provider)}
          organizer={venue}
          location={{
            type: 'Place',
            name: venue,
            address: {
              type: 'PostalAddress',
              addressLocality: city,
              postalCode: zipCode,
              addressCountry: 'DK',
            },
          }}
          name={title}
          startDate={date}
          endDate={date}
          description={description}
        />
        <div className="event">
          <div
            style={{ backgroundImage: `url(${handleImage(image, provider)})` }}
            className="event__background"
          >
            <img
              className="event__image"
              src={handleImage(image, provider)}
              alt={title}
            />
          </div>
          <div className="event__content">
            <h1 className="title">
              <span>{title}</span>
            </h1>
            <div className="event__info">
              <div className="event__info-left">
                <h2 className="event__promoter">{venue}</h2>
                <h2 className="event__date">
                  <span>{handleDate(date)}</span>
                </h2>
                <h2 className="event__date">{handleClock(clock)}</h2>
                <h2 className="event__date">
                  <span>{`${zipCode} ${city}`}</span>
                </h2>
              </div>
              <div className="event__info-right">
                <Link
                  className={`btn event__link info__buyticket-link${
                    title.toLowerCase().match('udsolgt') ||
                    title.toLowerCase().match('aflyst') ||
                    onsale !== 'onsale' ||
                    onsale !== true
                      ? ' offsale'
                      : ' onsale'
                  }`}
                  to={`/redirect/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{handleStatus()}</span>
                </Link>
              </div>
            </div>
            {description && (
              <div
                className="event__description"
                dangerouslySetInnerHTML={{ __html: `<p>${description}</p>` }}
              />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

EventTemplate.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    clock: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    venue: PropTypes.string.isRequired,
    zipCode: PropTypes.string.isRequired,
    keywords: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      .isRequired,
    onsale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
};

export default EventTemplate;
